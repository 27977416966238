import { atom } from 'recoil';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';

export const dealerList = ["kccauto", "demo", "autostadt"];

export const menuList = {
    DLRDEMO : [
        {
            id: 1,
            title: "보증연장상품",
            child: [
                {
                    id: 1,
                    title: "계약서작성",
                    route: "/contracts/select",
                    icon: <EditNoteOutlinedIcon />
                },
                {
                    id: 2,
                    title: "계약서목록",
                    route: "/ewList",
                    icon: <ListAltIcon/>
                },
                {
                    id: 3,
                    title: "임시보관함",
                    icon: <InsertDriveFileOutlinedIcon/>
                }
            ]
        },
        {
            id: 2,
            title: "서비스동의서",
            child: [
                {
                    id: 4,
                    title: "동의서작성",
                    icon: <ListAltIcon/>
                },
                {
                    id: 5,
                    title: "동의서목록",
                    icon: <EditNoteOutlinedIcon />
                }
            ]
        }
    ],
    DLR00001 : [
        {
            id: 1,
            title: "보증연장상품",
            child: [
                {
                    id: 1,
                    title: "계약서작성",
                    route: "/contracts/select",
                    icon: <EditNoteOutlinedIcon />
                },
                {
                    id: 2,
                    title: "계약서목록",
                    route: "/ewList",
                    icon: <ListAltIcon/>
                },
                // {
                //     id: 3,
                //     title: "임시보관함",
                //     icon: <InsertDriveFileOutlinedIcon/>
                // }
            ]
        },
    ],
    DLR00040 : [
        {
            id: 1,
            title: "자동차매매계약서",
            child: [
                {
                    id: 1,
                    title: "계약서작성",
                    route: "/contracts/select",
                    icon: <EditNoteOutlinedIcon />
                },
                {
                    id: 2,
                    title: "계약서목록",
                    route: "/contracts/car",
                    icon: <ListAltIcon />
                }
            ]
        },
    ]
};

export const activeMenu = atom({
    key:"activeMenu",
    default: [{}],
});