import { useState, useEffect } from 'react';

/**외부 */
import { useRecoilValue, useSetRecoilState } from 'recoil';

/**내부 */
import CPPaging from 'components/comm/CPPaging';
import CPSearchResult from 'components/comm/CPSearchResult';
import CPSearchCondition from 'components/comm/CPSearchCondition';
import { userState } from 'stores/UserState';
import useCurrentViewPort from 'hooks/useCurrentViewport';
import { alertState, loadingState } from 'stores/ComponentState';
import * as crm4u from 'services/apis/crm4u/crmApi';
import * as signOk from 'services/apis/signOk/signOkApi';
import * as utils from 'commons/utils';

/**mui */
import { Menu, MenuItem, Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import CPManualBtn from '../../../components/comm/CPManualBtn';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function CarList() {
    const userInfo = useRecoilValue(userState);
    const setAlertInfo = useSetRecoilState(alertState);
    const setLoadingInfo = useSetRecoilState(loadingState); //loading셋팅

    //검색조건
    const [ewDiv, setEwDiv] = useState([]);
    const [signokProgStatus, setSignokProgStatus] = useState([]);
    const [approvalProgStatus, setApprovalProgStatus] = useState([]);

    const [isClicked, setIsClicked] = useState(false);
    const [searchParam, setSearchParam] = useState({});
    const conditions = [
                        { type: 'text', label: '계약서번호', name: 'cont_no'},
                        { type: 'text', label: '고객명', name: 'client_nm'},
                        { type: 'date-range', breakPoints: {lg: 4, md: 4, sm: 6, xs: 12}, 
                          items: [{ label: '계약일', name: 'ew_product_sales_dt_s', start: true, link: 'contract_dt'}, 
                                  { label: '계약일', name: 'ew_product_sales_dt_e', end: true, link: 'contract_dt'}
                        ]},
                        { type: 'select', label: '서명상태', name: 'signok_prog_status', value: 'detail_cd_id', text: 'cd_nm', data: signokProgStatus, defaultMessage: '전체' },
                        { type: 'select', label: '품의서상태', name: 'approval_prog_status', value: 'detail_cd_id', text: 'cd_nm', data: approvalProgStatus, defaultMessage: '전체' },
                       ];

    //검색결과
    const [rows, setRows] = useState([]);
    const viewPort = useCurrentViewPort();
    const columns = [{ field: '서명상태' ,headerName: '서명상태', width: 110, align: 'left',
                        renderCell: (params) => signStatusComponent(params.row.서명상태코드, params.row.서명상태명)},
                    { field: '계약서번호', headerName: '계약서번호', minWidth: 80, maxWidth: 110, flex: 1 },
                    { field: '고객명', headerName: '고객명',minWidth: 80, maxWidth: 160, flex: 1 },
                    viewPort.xs ? null : { field: '모델명', headerName: '모델명', minWidth: 140, flex: 1 },
                    viewPort.sm || viewPort.xs ? null : { field: '차종', headerName: '차종', minWidth: 80, flex: 1 },
                    viewPort.sm || viewPort.xs ? null : { field: '연식', headerName: '연식', minWidth: 80, flex: 1 },
                    { field: '계약일', headerName: '계약일', minWidth: '110', valueGetter: (row) => utils.toStringByFormat(row.value)},
                    { field: 'docunemt_id', headerName: 'Action', flex: 1, renderCell: (row) => <ActionIcon row={row}/>}
                    ].filter(Boolean);

    
    //페이징
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(10);

    //Action 메뉴
    const [anchorActionEl, setAnchorActionEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(false);

    useEffect(() => {

        const init = async() => {
            try {
                setLoadingInfo({isOpen:true});

                let statusList = await crm4u.getCodeList({dealer_cd: userInfo.dealer_cd, grp_cd_id: 'SIGNOK_PROG_STATUS'});
                statusList = statusList?.filter((obj) => obj.detail_cd_id !== 'SPS00050');
                setSignokProgStatus(statusList);

                let approvalProgStatus = [{detail_cd_id: 'PGS00010', cd_nm: '작성'}, 
                                          {detail_cd_id: 'PGS00020', cd_nm: '계약'}, 
                                          {detail_cd_id: 'PGS00030', cd_nm: '배정완료'}, 
                                          {detail_cd_id: 'PGS00040', cd_nm: '출고예정'}, 
                                          {detail_cd_id: 'PGS00050', cd_nm: '출고'},
                                          {detail_cd_id: 'PGS00060', cd_nm: '해약'},
                                         ];
                setApprovalProgStatus(approvalProgStatus);
                
                setIsClicked(true);
            } catch (err) {
                setIsClicked(false);
                setAlertInfo({message :err, isOpen:true});    
            } finally {
                setLoadingInfo({isOpen:false});
            }
        }

        init();
    }, []);

    useEffect(() => {
        if(isClicked) {
            getCarContractList(1);
            setIsClicked(false);
        }
    }, [searchParam, isClicked]);
    
    const handleClick = (searchParam) => {
        setIsClicked(true);
        setSearchParam(searchParam);
    }

    const getCarContractList = async (page) => {
        setLoadingInfo({isOpen:true});
        try {
            
            const contractList = [
                {
                    seq: 1,
                    서명상태코드: 'SPS00020',
                    서명상태명: '서명완료',
                    계약서번호: '152433',
                    고객명: '홍길동',
                    모델명: 'Taycan',
                    차종: 'Taycan Turbo S',
                    연식: '2024',
                    계약일: '2024-11-01'
                },
                {
                    seq: 2,
                    서명상태코드: 'SPS00010',
                    서명상태명: '서명중',
                    계약서번호: '145839',
                    고객명: '김철수',
                    모델명: 'Panamera',
                    차종: 'Panamera 4 E Hybrid (4인승)',
                    연식: '2025',
                    계약일: '2024-10-25'
                },
                {
                    seq: 3,
                    서명상태코드: 'SPS00020',
                    서명상태명: '서명완료',
                    계약서번호: '163745',
                    고객명: '김지훈',
                    모델명: 'Panamera',
                    차종: 'G3 Panamera 4',
                    연식: '2024',
                    계약일: '2024-10-23'
                },
                {
                    seq: 4,
                    서명상태코드: 'SPS00020',
                    서명상태명: '서명완료',
                    계약서번호: '184667',
                    고객명: 'KCC정보통신(주)',
                    모델명: '911',
                    차종: 'Carrera GTS',
                    연식: '2025',
                    계약일: '2024-09-05'
                },
                {
                    seq: 5,
                    서명상태코드: 'SPS00030',
                    서명상태명: '서명거부',
                    계약서번호: '184776',
                    고객명: '정영훈',
                    모델명: 'Cayenne',
                    차종: 'Cayenne',
                    연식: '2024',
                    계약일: '2024-07-05'
                },
            ];

            setPage(page);
            setTotalCount(5);
            setRows(contractList);
        } catch (error) {
            setAlertInfo({message :error, isOpen:true});
        } finally {
            setLoadingInfo({isOpen:false});
        }
    }

    const deleteDocument = () => {
        alert("준비중입니다.")
        // if(window.confirm('계약서를 삭제하시겠습니까?')) {
        //     setLoadingInfo({isOpen:true})

        //     const ewContNo = selectedRow?.row?.ew_cont_no;

        //     crm4u.deleteEwMng(ewContNo)
        //         alert('계약서 삭제를 완료하였습니다.');
        //         setLoadingInfo({isOpen:false})
        // }
    }

    const ActionIcon = ({row}) => {
        return (
            <div>
                <MoreHorizIcon
                onClick={(e) => {
                    setSelectedRow(row);
                    setAnchorActionEl(e.currentTarget);
                }}
                >
                </MoreHorizIcon>
            </div>
        );
    }

    const ActionMenuList = () => {
        const isSigning = selectedRow?.row?.서명상태코드 === 'SPS00010';

        const 품의서연동 = () => {
            // if(!window.confirm('해당 계약 내용의 품의서를 생성하시겠습니까?')) return;

            alert('준비중입니다.');
        }

        return (
            <Menu
              anchorEl={anchorActionEl}
              open={Boolean(anchorActionEl)}
              onClose={() => setAnchorActionEl(null)}
            >
                <MenuItem onClick={() => openDocument(selectedRow)}>계약서 보기</MenuItem>
                {isSigning && <MenuItem onClick={() => deleteDocument(selectedRow)}>계약서 삭제</MenuItem>}
                <MenuItem onClick={품의서연동}>품의서 생성</MenuItem>
            </Menu>
        )
    }

    const openDocument = (row) => {
        setLoadingInfo({isOpen:true});
        
        // if(row?.row?.ew_signok_prog_status === 'ESPS00010') {
        //     window.open(row?.row?.view_url, '_blank');
        //     setLoadingInfo({isOpen:false})
        //     return;
        // }
        
        // if(!row?.row?.document_id) {
        //     setAlertInfo({message :'계약서가 존재하지 않습니다.', isOpen:true});
        //     setLoadingInfo({isOpen:false});
        //     return;
        // }

        const popup = window.open('', '_blank');
        signOk.getSignOkDocument('c9ccdf67ce23470188292d1b3b23bfa6')
            .then((res) => {
                popup.location.href = res.result.view_url
            })
            .catch((err) => {
                popup.close();
                setAlertInfo({message :err, isOpen:true})
            })
            .finally(() => {
                setLoadingInfo({isOpen:false})
            });
    }   

    const signStatusComponent = (code, name) => {

        const component = {
            SPS00010:  <>
                            <HourglassEmptyRoundedIcon fontSize='small' color='primary'/>
                            <Typography pl={0.5} fontWeight={'bold'} color={'primary'}>{name}</Typography>
                        </>,
            SPS00020:  <>
                            <CheckCircleRoundedIcon fontSize='small' color='warning'/>
                            <Typography pl={0.5} fontWeight={'bold'} color={'#ed6c02'}>{name}</Typography>
                        </>,
            SPS00030:  <>
                            <CancelIcon fontSize='small' color='error'/>
                            <Typography pl={0.5} fontWeight={'bold'} color={'error'}>{name}</Typography>
                        </>
        }
        
        return component[code];
    }

    return (
        <>
            <CPSearchCondition onSearch={handleClick} conditions={conditions}/>
            <CPSearchResult columns={columns } rows={rows} rowId={'seq'} totalCount={totalCount} />
            <CPPaging page={page} totalItemsCount={totalCount} func={getCarContractList} itemsCountPerPage={itemsCountPerPage}/>
            <CPManualBtn url={''}/>
            <ActionMenuList />
        </>
    );
};

export default CarList;