import { useState, useEffect } from 'react';

/**외부 */
import { useRecoilValue, useSetRecoilState } from 'recoil';

/**내부 */
import CPPaging from 'components/comm/CPPaging';
import CPSearchResult from 'components/comm/CPSearchResult';
import CPSearchCondition from 'components/comm/CPSearchCondition';
import { userState } from 'stores/UserState';
import useCurrentViewPort from 'hooks/useCurrentViewport';
import { alertState, loadingState } from 'stores/ComponentState';
import * as crm4u from 'services/apis/crm4u/crmApi';
import * as signOk from 'services/apis/signOk/signOkApi';
import * as utils from 'commons/utils';

/**mui */
import { Menu, MenuItem, Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import CPManualBtn from 'components/comm/CPManualBtn';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function EwList() {
    const userInfo = useRecoilValue(userState);
    const setAlertInfo = useSetRecoilState(alertState);
    const setLoadingInfo = useSetRecoilState(loadingState); //loading셋팅

    //검색조건
    const [ewDiv, setEwDiv] = useState([]);
    const [ewSignokProgStatus, setEwSignokProgStatus] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const [searchParam, setSearchParam] = useState({});
    const conditions = [{ type: 'select', label: '상태', name: 'ew_signok_prog_status', value: 'detail_cd_id', text: 'cd_nm', data: ewSignokProgStatus, defaultMessage: '전체' },
                        { type: 'select', label: '상품', name: 'ew_product_cd', value: 'detail_cd_id', text: 'cd_nm', data: ewDiv, defaultMessage: '전체' },
                        { type: 'date-range', breakPoints: {lg: 4, md: 4, sm: 6, xs: 12}, //breaPoints는 참고용으로 추가했고, 기본값과 같음
                          items: [{ label: '상품판매일', name: 'ew_product_sales_dt_s', start: true, link: 'ew_product_sales_dt_e'}, 
                                  { label: '상품판매일', name: 'ew_product_sales_dt_e', end: true, link: 'ew_product_sales_dt_s'}
                                 ]},
                        { type: 'text', label: '고객명', name: 'ew_client_nm'},
                        { type: 'text', label: '차대번호', name: 'iso_vin'},
                        { type: 'text', label: '차량번호', name: 'car_no'}
                       ];

    //검색결과
    const [rows, setRows] = useState([]);
    const viewPort = useCurrentViewPort();
    const columns = [{ field: 'ew_signok_prog_status_nm' ,headerName: '상태', width: 110, align: 'left',
                        renderCell: (params) => signStatusComponent(params.row.ew_signok_prog_status, params.row.ew_signok_prog_status_nm)},
                    { field: 'ew_product_nm', headerName: '상품', minWidth: 80, maxWidth: 110, flex: 1 },
                    { field: 'ew_client_nm', headerName: '고객명', minWidth: 80, maxWidth: 160, flex: 1 },
                    viewPort.xs ? null : { field: 'model_nm', headerName: '모델명', maxWidth: 140, flex: 1 },
                    viewPort.sm || viewPort.xs ? null : { field: 'iso_vin', headerName: '차대번호', minWidth: 200, flex: 1 },
                    viewPort.md || viewPort.sm || viewPort.xs ? null : { field: 'car_no', headerName: '차량번호', flex: 1 },
                    { field: 'ew_product_sales_dt', headerName: '상품판매일', minWidth: '110', valueGetter: (row) => utils.toStringByFormat(row.value)},
                    { field: 'document_id', headerName: 'Action', flex: 1, renderCell: (row) => <ActionIcon row={row}/>}
                    ].filter(Boolean);

    
    //페이징
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(10);

    //Action 메뉴
    const [anchorActionEl, setAnchorActionEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(false);

    useEffect(() => {

        const init = async() => {
            try {
                setLoadingInfo({isOpen:true});

                let statusList = await crm4u.getCodeList({dealer_cd: userInfo.dealer_cd, grp_cd_id: 'EW_SIGNOK_PROG_STATUS'});
                statusList = statusList?.filter((obj) => obj.detail_cd_id !== 'ESPS00050');
                setEwSignokProgStatus(statusList);

                const codeList = await crm4u.getCodeList({dealer_cd: userInfo.dealer_cd, grp_cd_id: 'EW_DIV'});
                setEwDiv(codeList);

                setIsClicked(true);
            } catch (err) {
                setIsClicked(false);
                setAlertInfo({message :err, isOpen:true});    
            } finally {
                setLoadingInfo({isOpen:false});
            }
        }

        init();
    }, []);

    useEffect(() => {
        if(isClicked) {
            getEwList(1);
            setIsClicked(false);
        }
    }, [searchParam, isClicked]);
    
    const handleClick = (searchParam) => {
        setIsClicked(true);
        setSearchParam(searchParam);
    }

    const getEwList = async (page) => {
        setLoadingInfo({isOpen:true});
        try {
            const ewData = await crm4u.getEwList({...searchParam, 
                                                    dealer_cd: userInfo.dealer_cd, 
                                                    status: 'ALL', 
                                                    writer: userInfo.emp_id, 
                                                    pageNum: page, 
                                                    size: 10});
            setPage(page);
            setTotalCount(ewData?.totalCount??0);
            setRows(ewData?.ewList??[{}]);
        } catch (error) {
            setAlertInfo({message :error, isOpen:true});
        } finally {
            setLoadingInfo({isOpen:false});
        }
    }

    const deleteDocument = () => {
        if(window.confirm('계약서를 삭제하시겠습니까?')) {
            setLoadingInfo({isOpen:true})

            const ewContNo = selectedRow?.row?.ew_cont_no;

            crm4u.deleteEwMng(ewContNo)
                .then((res) => {
                    alert('계약서 삭제를 완료하였습니다.');
                    setAnchorActionEl(null);
                    getEwList(1);
                })
                .catch((err) => {
                    setAlertInfo({message :err, isOpen:true})
                })
                .finally(() => {
                    setLoadingInfo({isOpen:false})
                });
        }
    }

    const ActionIcon = ({row}) => {
        return (
            <div>
                <MoreHorizIcon
                onClick={(e) => {
                    setSelectedRow(row);
                    setAnchorActionEl(e.currentTarget);
                }}
                >
                </MoreHorizIcon>
            </div>
        );
    }

    const ActionMenuList = () => {
        const isSigning = selectedRow?.row?.ew_signok_prog_status === 'ESPS00010';

        return (
            <Menu
              anchorEl={anchorActionEl}
              open={Boolean(anchorActionEl)}
              onClose={() => setAnchorActionEl(null)}
            >
                <MenuItem onClick={() => openDocument(selectedRow)}>계약서 보기</MenuItem>
                {isSigning && <MenuItem onClick={() => deleteDocument(selectedRow)}>계약서 삭제</MenuItem>}
            </Menu>
        )
    }

    const openDocument = (row) => {
        setLoadingInfo({isOpen:true});
        
        if(row?.row?.ew_signok_prog_status === 'ESPS00010') {
            window.open(row?.row?.view_url, '_blank');
            setLoadingInfo({isOpen:false})
            return;
        }
        
        if(!row?.row?.document_id) {
            setAlertInfo({message :'계약서가 존재하지 않습니다.', isOpen:true});
            setLoadingInfo({isOpen:false});
            return;
        }

        const popup = window.open('', '_blank');
        signOk.getSignOkDocument(row?.row?.document_id)
            .then((res) => {
                popup.location.href = res.result.view_url
            })
            .catch((err) => {
                popup.close();
                setAlertInfo({message :err, isOpen:true})
            })
            .finally(() => {
                setLoadingInfo({isOpen:false})
            });
    }   

    const signStatusComponent = (code, name) => {

        const component = {
            ESPS00010:  <>
                            <HourglassEmptyRoundedIcon fontSize='small' color='primary'/>
                            <Typography pl={0.5} fontWeight={'bold'} color={'primary'}>{name}</Typography>
                        </>,
            ESPS00020:  <>
                            <CheckCircleRoundedIcon fontSize='small' color='warning'/>
                            <Typography pl={0.5} fontWeight={'bold'} color={'#ed6c02'}>{name}</Typography>
                        </>,
            ESPS00030:  <>
                            <CancelIcon fontSize='small' color='error'/>
                            <Typography pl={0.5} fontWeight={'bold'} color={'error'}>{name}</Typography>
                        </>
        }
        
        return component[code];
    }

    return (
        <>
            <CPSearchCondition onSearch={handleClick} conditions={conditions}/>
            <CPSearchResult columns={columns } rows={rows} rowId={'seq'} totalCount={totalCount} />
            <CPPaging page={page} totalItemsCount={totalCount} func={getEwList} itemsCountPerPage={itemsCountPerPage}/>
            <CPManualBtn url={'https://dev.kcc.co.kr/wiki/pages/viewpage.action?pageId=45293420'}/>
            <ActionMenuList />
        </>
    );
};

export default EwList;