import React, { useEffect, useState} from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';

/**mui */
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import{ HelperTextBox } from 'styles/ewMng/EwFormStyle';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

/**내부 */
import { userState } from 'stores/UserState';
import { alertState } from 'stores/ComponentState';
import * as crm4u from 'services/apis/crm4u/crmApi';
import * as signOk from 'services/apis/signOk/signOkApi';
import 'styles/ewMng/EwElecForm.css';
import * as utils from 'commons/utils';

function EwElecForm() {

    const userInfo = useRecoilValue(userState); //사용자정보
    const setAlertInfo = useSetRecoilState(alertState); //alert셋팅
    const navigate = useNavigate(); //페이지이동
    const location = useLocation();
    const {url, ewData, templateData}  = location.state;
    const [infoText, setInfoText] = useState('전자계약서 작성중');
    const [infoIcon, setInfoIcon] = useState('');

    const [virtualAccountNo, setVirtualAccountNo] = useState("");//가상계좌번호
    
    const { register,
        handleSubmit,
        setValue,
        formState: { errors },
        } = useForm({
                        defaultValues:{
                            msg_body: '',
                            send_phone: ewData.sellShowroomPhoneNo, 
                            send_name: userInfo.emp_id, 
                            dest_phone: ewData.ewClientPhone, 
                            dest_name: ewData.ew_client_nm
                        }
                    });

    useEffect(() => {
        utils.openPopup({
            url: url,
            type: '_blank',
            fail: () => {
                alert("팝업이 차단되어 전자계약서를 불러오는데 실패했습니다.\n\n차단 해제(팝업허용) 후 '전자계약서 보기' 버튼을 클릭해주세요.");
            }
        });

        window.addEventListener('message', (event) => messageHandler(event));

        return () => {
            window.removeEventListener('message',(event) => messageHandler(event)); 
        }
    },[]);
    
    const messageHandler = (event) => {
        // Handle the response message here
        let resultData =  JSON.parse(event.data);
    
        let requestParam = {
            ew_cont_no : ewData.ewContNo,
            dealer_cd : ewData.dealer_cd,
            writer : userInfo.emp_id,
            tax_bill_req_cd : ewData.tax_bill_req_cd,
            emp_nm : userInfo.emp_nm,
            showroom_nm : userInfo.showroom_nm
        };
        
        //data값에따라 분기처리
        let resultText = "";
        if(resultData.code === "EDPSC003") {
            resultText = "서명완료";
            requestParam = {
                ...requestParam,
                status : "Y",
                ew_signok_prog_status : "ESPS00020"
            };
        }
        else if(resultData.code === "EDPSC002") {
            resultText = "서명거부";
            requestParam = {
                ...requestParam,
                status : "N",
                ew_signok_prog_status : "ESPS00030"
            };
        }
        else {
            resultText = "작성오류";
            requestParam = {
                ...requestParam,
                status : "N",
                ew_signok_prog_status : "ESPS00040"
            };
        }

        setInfoText('전자계약서 ' + resultText);
        setInfoIcon(requestParam.ew_signok_prog_status);
    }
    
    /**
     * 가상계좌 할당
     */
    const getVirtualAccount = async () => {

        if(window.confirm("가상계좌를 할당받으시겠습니까?")){
                crm4u.ewAssignVirtualAccount(ewData.ewContNo).then((virtaulAccNo) => { 
                setVirtualAccountNo(virtaulAccNo);  
                setValue("msg_body", 
                        `안녕하세요, ${ewData.ew_client_nm} 고객님\n`+
                        `EW 결제대금 입금계좌 전달드립니다.\n\n`+
                        `상품명 : ${ewData.ewDivCdNm}\n`+
                        `상품가격 : ${utils.setComma(ewData.sell_price)}원\n`+
                        `가상계좌 : ${virtaulAccNo}(신한은행)\n`+
                        `예금주 : ${userInfo.dealer_nm}(주)\n\n`+
                        `감사합니다.`
                        );
            }).catch((err) => { 
                setVirtualAccountNo("");
                setAlertInfo({message :err, isOpen:true});
            })    
        }
    }

    /**
     * SMS 전송 (가상계좌 할당 후 사용 가능)
     */
    const sendSMS = (smsData) => {
        if(window.confirm("정말 발송하시겠습니까?")) {
            crm4u.sendSMS(smsData).then((res) => {
                alert(res);
                setValue("msg_body", "");
                setValue("dest_phone", "");
            }).catch((err) => {
                setAlertInfo({message :err, isOpen:true});
            });
        }
    }
    
    /**
     * 진행상태 아이콘
     */
    function InfoIcon() {
        if(infoIcon === "ESPS00020") {
            return (<CheckCircleOutlineIcon sx={{ fontSize: 54, color:'#0785ff', marginBottom:'20px'}} />);
        } else if(infoIcon === "ESPS00030" || infoIcon === "ESPS00040"){
            return (<CancelOutlinedIcon  sx={{ fontSize: 54, color:'#d32f2f', marginBottom:'20px'}} />);
        } else {
            return (<PendingOutlinedIcon  sx={{ fontSize: 54, marginBottom:'20px'}} />);
        }
    }
    

    return (
        <div className='result-wrap'>
            <div className='result-area'>
                <Stack direction='column'  alignItems='center'>
                    <InfoIcon />
                    <div className='result-text'>{infoText}</div>
                    <div className='text-style1'>
                        전자계약서 보기 
                        <FileOpenOutlinedIcon sx={{cursor: 'pointer'}} onClick={() => {window.open(url, "_blank");}}/> 
                    </div>
                </Stack>
            </div>
            <div className='content-area'>
                <form onSubmit={handleSubmit(sendSMS)}>
                    <Stack sx={{width: "380px"}} spacing={4}>
                        {(infoIcon === 'ESPS00020' &&
                        <>
                            <Card sx={{padding:'5px'}}>
                                <CardHeader subheader="카드결제시 계약서번호 입력"
                                            action={
                                                <ExpandCircleDownIcon sx={{cursor: 'pointer'}} />
                                            }
                                            className='text-style2' 
                                            sx={{width:'auto', '& .MuiCardHeader-subheader':{color:'#2196f3'} }} />
                
                                <CardContent>
                                    <div className='subtitle'>계약정보</div>
                                    <div className='column-info'>
                                        <div className='column-title'>계약서번호</div>
                                        <div className='column-right'>{ewData.ewContNo}</div>
                                    </div>
                                    <Divider />
                                    <div className='column-info'>
                                        <div className='column-title'>상품명</div>
                                        <div className='column-right'>{ewData.ewDivCdNm}</div>
                                    </div>                                    
                                    <Divider />
                                    <div className='column-info'>
                                        <div className='column-title'>판매가격</div>
                                        <div className='column-right'>{utils.setComma(ewData.sell_price)}</div>
                                    </div>                                    
                                    <Divider />
                                </CardContent>
                            </Card>
                            <Card sx={{padding:'5px'}}>
                                <CardHeader subheader="가상계좌 발급 및 SMS전송"
                                            action={
                                                <ExpandCircleDownIcon sx={{cursor: 'pointer'}} />
                                            }
                                            className='text-style2'
                                            sx={{width:'auto', '& .MuiCardHeader-subheader':{color:'#2196f3'}}} />
                                
                                <CardContent>
                                    {templateData?.signerId.length === 1 && (
                                        <>
                                        <div className='subtitle'>가상계좌</div>
                                        <Stack direction="row"  spacing={1} sx={{marginTop:'13px', marginBottom:'20px'}} >
                                            <TextField
                                                    label="가상계좌"
                                                    size="small"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }} 
                                                    InputProps={{readOnly: true}}
                                                    value={virtualAccountNo}
                                            /> 
                                            <Button variant="outlined" 
                                                        type="button"
                                                        onClick={getVirtualAccount}
                                                        disabled={!!virtualAccountNo}>
                                                발급
                                            </Button>
                                        </Stack>
                                        <div className='subtitle'>SMS</div>
                                        <TextField
                                                    label="발송내용"
                                                    multiline
                                                    fullWidth
                                                    rows={10}
                                                    sx={{marginTop:'13px', marginBottom:'20px'}}
                                                    InputLabelProps={{ shrink: true }}
                                                    {...register("msg_body")}
                                        />
                                        <TextField
                                                    label="보내는사람"
                                                    size="small"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{ maxLength: 13}} 
                                                    className= {errors?.send_phone && "validation-error"} 
                                                    {...register("send_phone", {required: "보내는사람 전화번호를 입력하세요.", 
                                                    pattern: {value: utils.regExp("phone"), message: "전화번호 형식을 확인하세요."},
                                                    onChange: (e) => {setValue("send_phone", e.target.value)}})}
                                            />    
                                        {errors?.send_phone &&<HelperTextBox sx={{color: "#d32f2f", margin: "5px 0 !important", width: "100% !important"}}>{errors?.send_phone?.message}</HelperTextBox>}    
                                        <TextField
                                                    label="받는사람"
                                                    size="small"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    sx={{marginTop:'20px'}}
                                                    inputProps={{ maxLength: 13}} 
                                                    className= {errors?.dest_phone && "validation-error"} 
                                                    {...register("dest_phone", {required: "받는사람 전화번호를 입력하세요.", 
                                                    pattern: {value: utils.regExp("phone"), message: "전화번호 형식을 확인하세요."},
                                                    onChange: (e) => {setValue("dest_phone", e.target.value)}})}
                                            />     
                                        {errors?.dest_phone &&<HelperTextBox sx={{color: "#d32f2f", margin: "5px 0 !important", width: "100% !important"}}>{errors?.dest_phone?.message}</HelperTextBox>}
                                        <Button variant="outlined"
                                                    type="submit"
                                                    fullWidth
                                                    sx={{marginTop:'20px'}}
                                                    disabled={!virtualAccountNo}
                                                    >
                                            SMS 발송
                                        </Button>
                                        </>    
                                    )}
                                    {templateData?.signerId.length !== 1 && (
                                        <Alert severity="info" size="small"  sx={{ wordBreak: "keep-all" }}>고객 서명 완료 후 CRM4U EW 계약서 상세화면에서 가상계좌를 발급받으실 수 있습니다.</Alert>
                                    )}
                                </CardContent> 
                            </Card>
                    </>
                    )}   
                    </Stack>
                </form>
            </div>
            <div className='footer-btn'>
                <Button fullWidth variant="contained" size="large" 
                    onClick={() => {navigate("/contracts/select")}}> 
                    전자계약서 작성화면으로 가기
                </Button>
            </div>
        </div>
    );
};

export default EwElecForm;