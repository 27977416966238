import React, { useEffect, useState} from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';

/**mui */
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

/**내부 */
import 'styles/ewMng/EwElecForm.css';
import * as utils from 'commons/utils';

function CarSignStateForm() {
    const navigate = useNavigate(); //페이지이동
    const location = useLocation();
    const {url, data}  = location.state;
    const [car, contract, client] = [data.car, data.contract, data.client];
    const [infoText, setInfoText] = useState('전자계약서 작성중');
    const [resultCode, setResultCode] = useState('');

    useEffect(() => {
        utils.openPopup({
            url: url,
            type: '_blank',
            fail: () => {
                alert("팝업이 차단되어 전자계약서를 불러오는데 실패했습니다.\n\n차단 해제(팝업허용) 후 '전자계약서 보기' 버튼을 클릭해주세요.");
            }
        });

        window.addEventListener('message', (event) => messageHandler(event));

        return () => {
            window.removeEventListener('message',(event) => messageHandler(event)); 
        }
    },[]);
    
    const messageHandler = (event) => {

        const resultData =  JSON.parse(event.data);

        const infoText = {
            "EDPSC002": "서명거부",
            "EDPSC003": "서명완료"
        }
        
        //data값에따라 분기처리
        setResultCode(resultData.code);
        setInfoText(`전자계약서 ${infoText[resultData.code]??"작성오류"}`);
    }
    console.log(resultCode);
    /**
     * 진행상태 아이콘
     */
    function InfoIcon() {
        //서명완료
        if(resultCode === "EDPSC003") { 
            return (<CheckCircleOutlineIcon sx={{ fontSize: 54, color:'#0785ff', marginBottom:'20px'}} />);
        }
        
        //서명거부
        if(resultCode === "EDPSC002"){
            return (<CancelOutlinedIcon  sx={{ fontSize: 54, color:'#d32f2f', marginBottom:'20px'}} />);
        }

        //작성중
        return (<PendingOutlinedIcon  sx={{ fontSize: 54, marginBottom:'20px'}} />);
    }
    

    return (
        <div className='result-wrap' style={{height: '80vh'}}>
            <div className='result-area'>
                <Stack direction='column'  alignItems='center'>
                    <InfoIcon />
                    <div className='result-text'>{infoText}</div>
                    <div className='text-style1'  onClick={() => {window.open(url, "_blank");}}>
                        전자계약서 보기 
                        <FileOpenOutlinedIcon sx={{cursor: 'pointer'}}/> 
                    </div>
                </Stack>
            </div>
            <div className='content-area'>
                <form>
                    <Stack sx={{width: "380px"}} spacing={4}>
                        {(resultCode === 'EDPSC003' &&
                        <>
                            <Card sx={{padding:'5px'}}>
                                <CardHeader subheader="계약 정보"
                                            action={
                                                <ExpandCircleDownIcon sx={{cursor: 'pointer'}} />
                                            }
                                            className='text-style2' 
                                            sx={{width:'auto', '& .MuiCardHeader-subheader':{color:'#2196f3'} }} />
                
                                <CardContent>
                                    <div className='column-info'>
                                        <div className='column-title'>계약서번호</div>
                                        <div className='column-right'>{contract.cont_no}</div>
                                    </div>
                                    <Divider />
                                    <div className='column-info'>
                                        <div className='column-title'>주문 형태</div>
                                        <div className='column-right'>{car.order_div}</div>
                                    </div>
                                    <Divider />
                                    <div className='column-info'>
                                        <div className='column-title'>고객명</div>
                                        <div className='column-right'>{client.client_nm}</div>
                                    </div>                                    
                                    <Divider />
                                    <div className='column-info'>
                                        <div className='column-title'>모델</div>
                                        <div className='column-right'>{car.model}</div>
                                    </div>                                    
                                    <Divider />
                                    <div className='column-info'>
                                        <div className='column-title'>차종</div>
                                        <div className='column-right'>{car.class}</div>
                                    </div>                                    
                                    <Divider />
                                    <div className='column-info'>
                                        <div className='column-title'>연식</div>
                                        <div className='column-right'>{car.year}</div>
                                    </div>                                    
                                    <Divider />
                                    <div className='column-info'>
                                        <div className='column-title'>차량가격</div>
                                        <div className='column-right'>{utils.setComma(car.car_price)}</div>
                                    </div>                                    
                                    <Divider />
                                </CardContent>
                            </Card>
                    </>
                    )}   
                    </Stack>
                </form>
            </div>
            <div className='footer-btn'>
                <Button fullWidth variant="contained" size="large" 
                    onClick={() => {navigate("/contracts/select")}}> 
                    전자계약서 작성화면으로 가기
                </Button>
            </div>
        </div>
    );
};

export default CarSignStateForm;