import React, { useEffect, useState, useCallback } from 'react';
/**외부 */
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';

/**mui */
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import{ TitleChip, ContentPaper, WarningTextBox } from '../../../styles/ewMng/EwFormStyle';
import { FormControl, RadioGroup, Radio, IconButton, Button, TextField, FormControlLabel, Checkbox, InputAdornment, Paper, Stack } from '@mui/material';
//mui icon
import SearchIcon from '@mui/icons-material/Search';
import CreateIcon from '@mui/icons-material/Create';
import ClearIcon from '@mui/icons-material/Clear';

/**내부 */
import 'styles/ewMng/EwForm.css';
import 'styles/base.css';
import * as utils from 'commons/utils';
import * as crm4u from 'services/apis/crm4u/crmApi';
import * as signOk from 'services/apis/signOk/signOkApi';
import { userState } from 'stores/UserState';
import { alertState, loadingState } from 'stores/ComponentState';
import CPDatePicker from 'components/comm/CPDatePicker';
import CPAppSearchModal from 'components/CPAppSearchModal';
import CPSelectTag from 'components/comm/CPSelectTag';
import CPClnSearchModal from 'components/CPClnSearchModal';

function CarForm() {
    const userInfo = useRecoilValue(userState); //사용자정보
    const setAlertInfo = useSetRecoilState(alertState); //alert셋팅
    const setLoadingInfo = useSetRecoilState(loadingState); //loading셋팅
    const location = useLocation();
    const navigate = useNavigate();
    const xsVal = 6;
    const smVal = 4;
    const mdVal = 4;

    /**템플릿 정보 */                 
    const {templateId, signerId, templateTitle, isNonFace} = location.state;

    const defaultValues = {
        contract: {
            cont_div: 'register',
            cont_dt: dayjs(new Date()),
            cont_no: '100001',
        },
        client: {
            gender: 'GEN00010',
            client_nm: '홍길동',
            phone_no: '010-1234-5678',
            email: 'crm4u@kcc.co.kr',
            birth: dayjs('1980-07-11'),
            bpid: '700062341',
            zip_code: '04308',
            address: '서울시 용산구 청파로 61길 5',
            address_detail: '6F 정보기술연구소',
            comp_nm: 'KCC정보통신(주)',
            comp_no: '106-81-04924',
            comp_reg_no: '110111-0113970'
        },
        car: {
            order_div: 'Order',
            model: '911',
            class: 'Carrera 4 Cabriolet',
            year: '2024',
            ext_color: 'Crayon',
            in_color: 'Bordeaux Red',
            soft_top_color: 'Red',
            comm_no: '878252',
            car_price: '228,500,000',
            cont_price: '5,000,000',
            balance: '223,500,000'
        }
    }

    const { register,
            handleSubmit,
            setValue,
            getValues,
            reset,
            watch,
            control,
            formState: { errors, isSubmitted },
      } = useForm({ 
                    defaultValues: defaultValues
                });

    const [showRoomList, setShowRoomList] = useState([{}]);
    const [contNoList] = useState([{cont_no: '100001'}, {cont_no: '100002'}]);
    const genderList = [{detail_cd_id: 'GEN00010', cd_nm: '남자'}, {detail_cd_id: 'GEN00020', cd_nm: '여자'}];
    const orderDivList = [{detail_cd_id: 'Pre-Order', cd_nm: 'Pre-Order'}, {detail_cd_id: 'Order', cd_nm: 'Order'}, {detail_cd_id: 'Stock', cd_nm: 'Stock'}]

    const [isNewClient, setIsNewClient] = useState(false);
    const [isContModalOpen, setIsContModalOpen] = useState(false);
    const [isClnModalOpen, setIsClnModalOpen] = useState(false);
    
    const [clnModalInfo, setClnModalInfo] = useState('');
        

    useEffect (() => {
        // ew관련 코드 값, 모델, 차종, 연식 데이터 조회
        crm4u.ewApiGetCode().then((res) =>{
            setShowRoomList(res.showRoomList);
        }).catch((err) => {
            setAlertInfo({message :err, isOpen:true});
        });
        
    },[]); //<--처음에만 호출하도록

    const onClnSearchModal = useCallback( async (props)=>{
        if(props.show) {
            const dept = await crm4u.getDeptList(userInfo.showroom_no);
            props = {
                ...props,
                showRoomList: showRoomList,
                deptList: dept.deptList,
                selectAuth: true
            }
            setClnModalInfo(props, setIsClnModalOpen(props.show));
        }else {
            setIsClnModalOpen(props.show);
        }
    });

    //고객구분 변경
    const handleClientDiv = (event) => {
        resetRegisterGroup('client');
        setIsNewClient(event.target.checked);
    }

    const inputBasicInfo = (row) => {
        console.log(row);
    }

    const inputClnInfo = (selRow) => {
        console.log(selRow)
        setValue('client.client_nm', selRow.client_nm);
        setValue('client.phone_no', selRow.phone_no);
    };

    //그룹핑 된 Register 값 초기화
    const resetRegisterGroup = (groupId) => {
        const currentValues = getValues();
        const groupDefaultValues = defaultValues[groupId];

        reset({
            ...currentValues,
            [groupId]: {
                ...Object.fromEntries(
                    Object.keys(currentValues[groupId]).map((key) => key === 'birth' ? [key, null] : [key, ''])
                )
          }
        });

        // reset({
        //     ...currentValues,
        //     [groupId]: {
        //         ...Object.fromEntries(
        //             Object.keys(currentValues[groupId]).map((key) => groupDefaultValues.hasOwnProperty(key) ? [key, groupDefaultValues[key]] : [key, ''])
        //         )
        //   }
        // });
    }

    const makeSignOkBody = (data) => {

        const text = (k, v) => {
            return {
                'key': k,
                'value': v
            }
        }
    
        const radio = (k) => {
            return {
                'key': k,
                'checked': true
            }
        }

        const orderDiv = {
            'Pre-Order' : 'order_div_pre_order',
            'Order' : 'order_div_order',
            'Stock' : 'order_div_stock'
        }

        const today = dayjs(new Date());
        
        const car = data.car;
        const client = data.client;
        const contract = data.contract;

        const signer = [
            {
                "id": signerId[0],
                "name": client.client_nm,
                "contact": utils.onlyNumber(contract.signer_phone_no2),
                "items":[
                    text("cont_no", contract.cont_no),
                    text("car_info", `${car.model} ${car.class} ${car.year}`),
                    text("showroom_nm", userInfo.showroom_nm),
                    text("cont_dt_year", contract.cont_dt.format("YYYY")),
                    text("cont_dt_month", contract.cont_dt.format("M")),
                    text("cont_dt_day", contract.cont_dt.format("D")),
                    radio(orderDiv[car.order_div]),
                    text("ext_color", car.ext_color),
                    text("in_color", car.in_color),
                    text("soft_top_color", car.soft_top_color),
                    text("comm_no", car.comm_no),
                    text("car_price", car.car_price),
                    text("cont_price", car.cont_price),
                    text("balance", car.balance),
                    text("emp_nm", userInfo.emp_nm),
                    text("today_year", today.format("YYYY")),
                    text("today_month", today.format("M")),
                    text("today_day", today.format("D"))
                ]
            }
        ];
    
        //서명자 2명인 경우 추가
        if(signerId.length === 2) {
            signer.push({
                "id": signerId[1],
                "name": signer[0]["name"],
                "contact": signer[0]["contact"],
                "items":[
                    radio(client.gender === "GEN00010" ? "gender_male" : "gender_female"),
                    text("address", `${client.address} ${client.address_detail}`),
                    text("phone_no", client.phone_no),
                    text("email", client.email),
                    text("client_nm", client.client_nm),
                    text("comp_nm", client.comp_nm),
                    text("comp_no", client.comp_no),
                    text("bpid", client.bpid)
                ]
            });

            signer[0].name =  userInfo.emp_nm;
            signer[0].contact =  utils.onlyNumber(contract.signer_phone_no1);
        }
    
        return signer;
    }

    const onSubmit = async (data) => {
        if(!window.confirm("전자계약서를 작성하시겠습니까?")) return;
        setLoadingInfo({isOpen:true});

        try {
            const templateData = {
                templateId: templateId,
                templateTitle: templateTitle,
            };

            const template = await signOk.createSignOkTemplate(templateData, makeSignOkBody(data));    


            navigate('/contracts/car/CarSignStateForm', {state: {url: template.result.signer[0].view_url ,data: data}});

        } catch (err) {
            setAlertInfo({message :err, isOpen:true});
        } finally {
            setLoadingInfo({isOpen:false});
        }
    }

    const onError = () => {
        alert('입력 값을 확인하세요.')
    }
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <ContentPaper elevation={3}>
                    <div className='subTitle'>
                        <TitleChip label="계약정보" icon={<CreateIcon/>}/>      
                    </div>
                    <Grid xs={xsVal} sm={smVal} md={mdVal} sx={{marginBottom: 3}}>
                        <Controller
                            name='contract.cont_div'
                            control={control}
                            render={({ field: {onChange, value} }) => (
                                <FormControl>
                                    <RadioGroup row onChange={(e) => {onChange(e); setValue("contract.cont_no", "");}} value={value}>
                                        <FormControlLabel value="register" control={<Radio size="small" />} label='신규 계약' />
                                        <FormControlLabel disabled value="modify" control={<Radio size="small" />} label='계약 수정' />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid container spacing={{ xs: 2, md: 3 }} >
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                                {
                                    watch('contract.cont_div') === 'register' &&
                                    <CPSelectTag 
                                        data={contNoList}
                                        name="contract.cont_no" 
                                        control={control}
                                        value="cont_no" 
                                        text="cont_no" 
                                        label="계약 번호"
                                        className='required'
                                        defaultMessage={"선택"}
                                        rules={{required: "계약 번호를 선택하세요."}}
                                    />
                                }
                                {
                                    watch('contract.cont_div') === 'modify' &&
                                    <TextField size='small'
                                        label="계약 번호"
                                        fullWidth
                                        className='required'
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ 
                                            readOnly: true,
                                            endAdornment: (<InputAdornment position="end">
                                                            {watch('contract.cont_no') ? <IconButton sx={{pr: 0}} onClick={() => setValue("contract.cont_no", "")}>
                                                                                                <ClearIcon  />
                                                                                    </IconButton>
                                                                                    :<IconButton sx={{pr: 0}}
                                                                                                onClick={() => setIsContModalOpen(true)}>
                                                                                                <SearchIcon />
                                                                                    </IconButton>}
                                                        </InputAdornment>)
                                        }}
                                        {...register('contract.cont_no', {required: "계약 번호를 선택하세요."})}
                                    />   
                                }
                                {errors?.contract?.cont_no && <WarningTextBox>{errors?.contract?.cont_no?.message}</WarningTextBox>}
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPDatePicker name="contract.cont_dt"
                                          label="계약일" 
                                          control={control} 
                            />
                        </Grid>
                    </Grid>
                </ContentPaper>
                <ContentPaper elevation={3}>
                    <div className='subTitle'>
                        <TitleChip label="고객정보" icon={<CreateIcon/>}/>
                        <FormControlLabel sx={{marginLeft: "3px"}} 
                                        label='신규고객'
                                        control={<Checkbox size="small" />}
                                        onChange={(event) => handleClientDiv(event)}
                        />
                    </div>
                    <Grid container spacing={{ xs: 2, md: 3 }} >
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField size='small'
                                    label="고객명"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    className='required'
                                    InputProps={{ 
                                        readOnly: !isNewClient,
                                                endAdornment: (
                                                    !isNewClient && 
                                                    <InputAdornment position="end">
                                                        <IconButton sx={{pr: 0}}
                                                                    onClick={()=>{onClnSearchModal({show: true})}}>
                                                                    <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                    }}
                                    {...register('client.client_nm')}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField size='small'
                                    label="휴대폰 번호"
                                    fullWidth
                                    className='required'
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: !isNewClient}}
                                    {...register('client.phone_no')}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPSelectTag 
                               name="client.gender" 
                               label="성별"
                               control={control}
                               data={genderList}
                               value="detail_cd_id" 
                               text="cd_nm" 
                               readOnly={!isNewClient}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPDatePicker name="client.birth"
                                          label="생년월일" 
                                          control={control} 
                                          readOnly={!isNewClient}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField size='small'
                                    label="이메일"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: !isNewClient}}
                                    {...register('client.email')}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField size='small'
                                    label="BPID"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: !isNewClient}}
                                    {...register('client.bpid')}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField size='small'
                                    label="우편번호"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: !isNewClient}}
                                    {...register('client.zip_code')}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField size='small'
                                    label="주소"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: !isNewClient}}
                                    {...register('client.address')}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField size='small'
                                    label="상세주소"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: !isNewClient}}
                                    {...register('client.address_detail')}
                            />
                        </Grid>
                        
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField size='small'
                                    label="법인명"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: !isNewClient}}
                                    {...register('client.comp_nm')}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField size='small'
                                    label="사업자 번호"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: !isNewClient}}
                                    {...register('client.comp_no')}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField size='small'
                                    label="법인 번호"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: !isNewClient}}
                                    {...register('client.comp_reg_no')}
                            />
                        </Grid>
                    </Grid>
                </ContentPaper>
                <ContentPaper elevation={3}>
                    <div className='subTitle'>
                        <TitleChip label="차량정보" icon={<CreateIcon/>}/>
                    </div>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPSelectTag
                                control={control}
                                data={orderDivList}
                                name="car.order_div" 
                                value="detail_cd_id" 
                                text="cd_nm" 
                                label="주문 형태"
                                className='required'
                            />  
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField size='small'
                                        label="Comm. No."
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        {...register('car.comm_no')}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField size='small'
                                    label="추가옵션 사항"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    {...register('car.add_option')}
                            />       
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPSelectTag 
                               name="car.model" 
                               label="모델"
                               control={control}
                               data={[{detail_cd_id: '911', cd_nm: '911'}]}
                               value="detail_cd_id" 
                               text="cd_nm" 
                               defaultMessage="선택"
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPSelectTag 
                               name="car.class" 
                               label="차종"
                               control={control}
                               data={[{detail_cd_id: 'Carrera 4 Cabriolet', cd_nm: 'Carrera 4 Cabriolet'}]}
                               value="detail_cd_id" 
                               text="cd_nm" 
                               defaultMessage="선택"
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPSelectTag 
                               name="car.year" 
                               label="연식"
                               control={control}
                               data={[{detail_cd_id: '2024', cd_nm: '2024'}, {detail_cd_id: '2023', cd_nm: '2023'}]}
                               value="detail_cd_id" 
                               text="cd_nm" 
                               defaultMessage="선택"
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPSelectTag 
                               name="car.ext_color" 
                               label="외장색상"
                               control={control}
                               data={[{detail_cd_id: 'Crayon', cd_nm: 'Crayon'}]}
                               value="detail_cd_id" 
                               text="cd_nm" 
                               defaultMessage="선택"
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPSelectTag 
                               name="car.in_color" 
                               label="내장색상"
                               control={control}
                               data={[{detail_cd_id: 'Bordeaux Red', cd_nm: 'Bordeaux Red'}]}
                               value="detail_cd_id" 
                               text="cd_nm" 
                               defaultMessage="선택"
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPSelectTag 
                               name="car.soft_top_color" 
                               label="Soft Top 색상"
                               control={control}
                               data={[{detail_cd_id: 'Red', cd_nm: 'Red'}]}
                               value="detail_cd_id" 
                               text="cd_nm" 
                               defaultMessage="선택"
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField
                                size='small'
                                fullWidth
                                label="판매 가격"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ 
                                    endAdornment: <InputAdornment position="end">원</InputAdornment>
                                }}
                                inputProps={{maxLength: 13}}
                                defaultValue='0'
                                
                                {...register('car.car_price', {onChange:(e)=> setValue("car.car_price", utils.setComma(e.target.value)??0)})}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField
                                size='small'
                                fullWidth
                                label="계약금"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ 
                                    endAdornment: <InputAdornment position="end">원</InputAdornment>
                                }}
                                inputProps={{maxLength: 13}}
                                defaultValue='0'
                                
                                {...register('car.cont_price', {onChange:(e)=> setValue("car.cont_price", utils.setComma(e.target.value)??0)})}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <TextField
                                size='small'
                                fullWidth
                                label="잔금"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ 
                                    endAdornment: <InputAdornment position="end">원</InputAdornment>
                                }}
                                inputProps={{maxLength: 13}}
                                defaultValue='0'
                                
                                {...register('car.balance', {onChange:(e)=> setValue("car.balance", utils.setComma(e.target.value)??0)})}
                            />
                        </Grid>
                    </Grid>     
                </ContentPaper>
                <Paper elevation={0} sx={{backgroundColor:"#e5f6fd", padding:"15px", marginBottom:"15px"}}>
                    <Stack mb={1} direction={{xs:'column', sm:'row', md:'row'}} spacing={2}>
                            <TextField
                                    label="담당자 휴대폰번호"
                                    defaultValue=""
                                    variant="standard"
                                    size='small'
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 13}} 
                                    className="required"
                                    {...register("contract.signer_phone_no1", {required: "전화번호를 입력하세요.", 
                                                                    pattern: {value: utils.regExp("phone"), message: "전화번호 형식을 확인하세요."}})}                               
                            />  
                    </Stack>
                    {errors.contract?.signer_phone_no1 && <WarningTextBox>{errors.contract?.signer_phone_no1?.message}</WarningTextBox>} 
                    <Stack mt={2} mb={1} direction={{xs:'column', sm:'row', md:'row'}} spacing={2}>
                            <TextField
                                    label="고객 휴대폰번호"
                                    defaultValue=""
                                    variant="standard"
                                    size='small'
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 13}} 
                                    className="required"
                                    {...register("contract.signer_phone_no2", {required: "전화번호를 입력하세요.", 
                                                                    pattern: {value: utils.regExp("phone"), message: "전화번호 형식을 확인하세요."}})}                               
                            />  
                            <div className="info-box" sx={{padding:"5px"}}>※ 해당번호의 카카오톡으로 완료된 전자계약서가 전달됩니다. </div>
                    </Stack>
                    {errors.contract?.signer_phone_no2 && <WarningTextBox>{errors.contract?.signer_phone_no2?.message}</WarningTextBox>}        
                </Paper>
                <div>
                    <Button fullWidth variant="contained" size="large" type="submit"> 전자계약서 작성  </Button>
                </div>
                {isContModalOpen && <CPAppSearchModal onBasicInfoModal={setIsContModalOpen} inputBasicInfo={inputBasicInfo}/>}
                {isClnModalOpen && <CPClnSearchModal {...clnModalInfo} onClnSearchModal={onClnSearchModal} inputClnInfo={inputClnInfo} />}
            </form>
        </div>
    )
}

export default CarForm;